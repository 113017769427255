import { Stack, Typography, useTheme } from '@mui/material';

import Heading from '~/components/atoms/Heading';
import Paragraph from '~/components/atoms/Paragraph';
import FrontContainer from '~/components/molecules/FrontContainer';
import BlankLayout from '~/components/templates/Blank';
import ErrorPageProps from './types';

import { useOptionalUser } from '~/utils/user';
import { AButton } from '@allvit/allvit-toolkit';

const ErrorPage = ({ children, status }: ErrorPageProps) => {
    const theme = useTheme();
    const title = status === 404 ? 'Finner ikke denne siden' : 'Å nei...';

    const currentUser = useOptionalUser();

    const description =
        status === 404
            ? 'Siden du leter etter finnes ikke. Prøv gjerne en annen adresse eller gå til forsiden.'
            : 'Du fant dessverre en feil i Allvit. Vi i Allvit har fått beskjed om feilen og skal fikse dette så fort vi klarer. Ta gjerne kontakt med support@allvit.no eller chat med oss hvis du trenger hjelp.';

    return (
        <BlankLayout showLogo sx={{ backgroundColor: theme.palette.background.default }}>
            <FrontContainer maxWidth="md">
                <Stack
                    sx={{
                        mb: 20,
                        pt: { xs: 0, md: 16 },
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 1,
                    }}
                >
                    <Heading variant="h1" sx={{ mb: 6, textAlign: { xs: 'center', md: 'left' } }}>
                        {title}
                    </Heading>
                    <Paragraph sx={{ mb: 12, textAlign: 'center' }}>{description}</Paragraph>

                    {currentUser?.signedIn ? (
                        <AButton href="/" variant="primary" size="large" sx={{ mb: 12 }}>
                            Gå til Start
                        </AButton>
                    ) : (
                        <AButton href="/" variant="primary" size="large" sx={{ mb: 12 }}>
                            Gå til forsiden
                        </AButton>
                    )}

                    {status !== 404 && (
                        <>
                            <Typography sx={{ mb: 4, textAlign: { xs: 'center', md: 'left' } }}>
                                Teknisk info:
                            </Typography>
                            <Typography sx={{ textAlign: { xs: 'center', md: 'left' } }}>{children}</Typography>
                        </>
                    )}
                </Stack>
            </FrontContainer>
        </BlankLayout>
    );
};

export default ErrorPage;
